import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { page, pageHeader, pageBody } from "./privacy.module.css";

const PrivacyPage = ({ location }) => {
  return (
    <Layout>
      <Seo pageTitle="Privacy" pagePath={location.pathname} />
      <main className={page}>
        <h1 className={pageHeader}>プライバシーポリシー</h1>
        <div className={pageBody}>
          <h2>個人情報の利用目的</h2>
          <p>
            当サイトでは、お問い合わせの際にメールアドレスを入力していただく必要がございます。
            <br />
            取得したメールアドレスは、お問い合わせに対する回答を電子メール等でご連絡する場合にのみ利用するものであり、これ以外の目的では利用いたしません。また、第三者に開示することもございません。
          </p>
          <h2>広告について</h2>
          <p>
            当サイトでは、第三者配信の広告サービス「Google
            AdSense」を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
            <br />
            クッキー（Cookie）を使用することで当サイトはお客様のコンピューターを識別できるようになりますが、個人を特定できるものではございません。
            クッキー（Cookie）を無効にする方法やGoogle AdSenseに関する詳細は
            <a href="https://policies.google.com/technologies/ads?hl=ja">
              「広告 – ポリシーと規約 – Google」
            </a>
            をご確認ください。
            <br />
            また、Amazonのアソシエイトとして、BeaSkyBlueは適格販売により収入を得ています。
          </p>
          <h2>アクセス解析ツールについて</h2>
          当サイトでは、アクセス解析ツール「Googleアナリティクス」を利用しており、トラフィックデータ収集のため、クッキー（Cookie）を使用しております。
          <br />
          トラフィックデータは匿名で収集されており、個人を特定できるものではございません。
          <h2>免責事項</h2>
          <p>
            当サイトからのリンクやバナー等で移動したサイトにおいて提供される情報、サービス等について一切の責任を負いません。
            また、当サイトのコンテンツについて、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではございません。情報が古くなっている場合もございます。
            当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default PrivacyPage;
